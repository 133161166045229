import './Home.css';
//assests import
import metamask_case from '../../assets/metamask-case-logo.png'
import mona_case from '../../assets/mona-case-logo.png'
import collabland_case from '../../assets/collabland-case-logo.png'
import telegram_case from '../../assets/telegram-case-logo.png'
import shopify_case from '../../assets/shopify-case-logo.png'
import discord_case from '../../assets/discord-case-logo.png'
import twitch_case from '../../assets/twitch-case-logo.png'
import cal_case from '../../assets/cal-case-logo.png'
import icon1 from '../../assets/icon-adv-1.png'
import icon2 from '../../assets/icon-adv-2.png'
import icon3 from '../../assets/icon-adv-3.png'
import icon4 from '../../assets/icon-adv-4.png'
import icon5 from '../../assets/icon-adv-5.png'
import icon6 from '../../assets/icon-adv-6.png'
import banner_icon_1 from '../../assets/banner-icon-1.png'
import banner_icon_2 from '../../assets/banner-icon-2.png'
import banner_icon_3 from '../../assets/banner-icon-3.png'
import newsletter_circle from '../../assets/newsletter_circle.png'
import home_step_one from '../../assets/home_step_one.png'
import home_step_two from '../../assets/home_step_two.png'
import home_step_three from '../../assets/home_step_three.png'
import droplove_light_logo from '../../assets/droplove_light_logo.png'

//libs imports
import React from "react";
import { Container } from '@material-ui/core'

const Home = () => {

  return (
    <div>
      <Container className="no-padding" maxWidth="xl">
        <div className="container-fluid">
          <div className="droplove-homepage-body">
            <div className="intro-section animate__animated animate__fadeIn">
              <h1>
                Lead Your Community<br />To Web3
              </h1>
              <p>
              Issue NFTs on your own smart contract without code. Onboard community to Web3 with digital access passes,<br/>collectibles, token gated sites, metaverse spaces or VIP allowlist memberships.<br/>For creators, marketers, gamers, artists and more…
              </p>
              <a target="_blank" href="https://app.droplove.xyz/create"><button className="full-btn-blue-big animate__animated animate__bounce">Issue NFTs</button></a>
              <br />
              <br />
              <br />
              <a href="https://www.producthunt.com/posts/droplove?utm_source=badge-top-post-badge&utm_medium=badge&utm_souce=badge-droplove" target="_blank"><img src="https://api.producthunt.com/widgets/embed-image/v1/top-post-badge.svg?post_id=352687&theme=neutral&period=daily" alt={"Droplove - No&#0045;code&#0032;tool&#0032;to&#0032;deploy&#0032;your&#0032;own&#0032;NFT&#0032;smart&#0032;contracts&#0046; | Product Hunt"} styles="width: 250px; height: 54px;" width="250" height="54" /></a>
              <br />
              <br />
              <br />
              <div className='intro-gif' styles={{ "width": "100%", "height": 0, "padding-bottom": "75%", "position": "relative" }}><iframe src="https://giphy.com/embed/GwRBmXyEOvFtK" width="100%" height="100%" styles="position:absolute" frameBorder="0" className="giphy-embed"></iframe></div>
            </div>
          </div>
        </div>

        <div className="container">
          <div className="droplove-case-section animate__animated animate__fadeIn">
            <div className='row'>
              <div className='case-section-pitch'>
                <p>Unlock your Web3 community potential with whitelist access, token - enabled memberships or cross platform roles on Discord and Telegram.</p>
              </div>
            </div>
            <div className="row">
              <div className="col-md-4">
                <div className="case-item">
                  <div className="case-item-imgscope">
                    <img src={mona_case} />
                  </div>
                  <h6>Access passes to Monaverse</h6>
                  <p>Organize meetups and private views on Monaverse for NFT key holders. </p>
                  {/* <a href="#"><button className="full-btn-lightblue">Read casestudy</button></a> */}
                </div>
              </div>
              <div className="col-md-4">
                <div className="case-item">
                  <div className="case-item-imgscope">
                    <img src={collabland_case} />
                  </div>
                  <h6>Collab.land Role Tokens</h6>
                  <p>Create community roles that stay the same across multiple Discord & Telegram Channels. </p>
                  {/* <a href="#"><button className="full-btn-lightblue">Read casestudy</button></a> */}
                </div>
              </div>
              <div className="col-md-4">
                <div className="case-item">
                  <div className="case-item-imgscope">
                    <img src={discord_case} />
                  </div>
                  <h6>Access to locked Discord groups</h6>
                  <p>Got more Discord channels? Create cross - Discord Roles, giving users same rights across different Discord Servers. </p>
                  {/* <a href="#"><button className="full-btn-lightblue">Read casestudy</button></a> */}
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-4">
                <div className="case-item">
                  <div className="case-item-imgscope">
                    <img src={telegram_case} />
                  </div>
                  <h6>NFT passes airdrops for VIP Telegram Channels.</h6>
                  <p>Create NFT contract that allows you to airdrop NFT access passes unlocking Telegram Channels. </p>
                  {/* <a href="#"><button className="full-btn-lightblue">Read casestudy</button></a> */}
                </div>
              </div>
              <div className="col-md-4">
                <div className="case-item">
                  <div className="case-item-imgscope">
                    <img src={metamask_case} />
                  </div>
                  <h6>Metamask Adoption</h6>
                  <p>Motivate your community to install web3 wallet with  free NFT airdrops, created without single line of code. </p>
                  {/* <a href="#"><button className="full-btn-lightblue">Read casestudy</button></a> */}
                </div>
              </div>
              <div className="col-md-4">
                <div className="case-item">
                  <div className="case-item-imgscope">
                    <img src={shopify_case} />
                  </div>
                  <h6>Token gated Shopify e-shops</h6>
                  <p>Airdrop NFT discount tokens which will unlock token gated e-commerce sites on Shopify. All without code. </p>
                  {/* <a href="#"><button className="full-btn-lightblue">Read casestudy</button></a> */}
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-2"></div>
              <div className="col-md-4">
                <div className="case-item">
                  <div className="case-item-imgscope">
                    <img src={twitch_case} />
                  </div>
                  <h6>Twitch fans reward NFTs</h6>
                  <p>Create simple smart contract without code, to airdrop NFTs to your most loyal fans. </p>
                  {/* <a href="#"><button className="full-btn-lightblue">Read casestudy</button></a> */}
                </div>
              </div>
              <div className="col-md-4">
                <div className="case-item">
                  <div className="case-item-imgscope">
                    <img src={cal_case} />
                  </div>
                  <h6>Access keys to book you on Cal.com</h6>
                  <p>Only wallets holders with access keys will be able to book your calendar slot. Good for web3 team management. </p>
                  {/* <a href="#"><button className="full-btn-lightblue">Read casestudy</button></a> */}
                </div>
              </div>
              <div className="col-md-2"></div>
            </div>
          </div>
        </div>

        <div className="container-fluid no-padding">
          <div className="droplove-banner-section animate__animated animate__fadeIn">
            <div className="banner-sc">
              <h2>Anyone can create access key NFTs</h2>
              <p>No experience with coding or blockchain is necessary.</p>
            </div>
            <div className='banner-home-start-here-btn'>
              <a target="_blank" href="https://app.droplove.xyz/create"><button className="banner-sc-btn btn-border">Where to start?</button></a>
            </div>
            <div className='sc-adv'>
              <div className='col-md-3'>
                <div className='sc-single'>
                  <div className='sc-single-img'>
                    <img src={banner_icon_1} />
                  </div>
                  <h2>1. Create smart contract</h2>
                  <p>Create and manage your own your contract, test on testnets, deploy on Ethereum mainnet, all without developers or knowledge of code.</p>
                </div>
              </div>
              <div className='col-md-3'>
                <div className='sc-single'>
                  <div className='sc-single-img'>
                    <img src={banner_icon_2} />
                  </div>
                  <h2>2. Create NFT's</h2>
                  <p>Pick visual for your NFT passes or attach a link to your custom made files.  No metadata or .json knowledge is needed. </p>
                </div>
              </div>
              <div className='col-md-3'>
                <div className='sc-single'>
                  <div className='sc-single-img'>
                    <img src={banner_icon_3} />
                  </div>
                  <h2>3. Airdrop to friends & community</h2>
                  <p>NFT's minted from your contract will be visible on Etherscan and selling on Opensea or other aggregators.</p>
                </div>
              </div>
              <div className='col-md-3'>
                <div className='sc-single'>
                  <div className='sc-single-img'>
                    <img src={banner_icon_3} />
                  </div>
                  <h2>4. Set up your gates</h2>
                  <p>Use your NFT passes to work as access tokens with platforms such as Guild.xyz or CollabLand.</p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className='container-fluid'>
          <div className='steps-section animate__animated animate__fadeIn'>
            <div className='row'>
              <div className='col-md-6 steps-section-img'>
                <img src={home_step_one} />
              </div>
              <div className='col-md-6 steps-section-text'>
                <h1>1. No-code Smart Contract<br />in 3 steps</h1>
                <p>Magic wizard allows you to deploy smart contract in 3 steps. No coding skills required.</p>
                <a target="_blank" href="https://app.droplove.xyz/create"><button className="full-btn-blue-medium">Create NFT Smart Contract</button></a>
              </div>
            </div>
          </div>
          <div className='steps-section'>
            <div className='row'>
              <div className='col-md-6 steps-section-text'>
                <h1>2. Dashboard for all<br />you need.</h1>
                <p>Once your contracts are created, you can manage them in Dashboard. Add or update content metadata, mint NFTs or transfer whole smart contract to new owner.</p>
                {/* <a><button>Create NFT Smart Contract</button></a> */}
              </div>
              <div className='col-md-6 steps-section-img'>
                <img src={home_step_two} />
              </div>
            </div>
          </div>
          <div className='steps-section'>
            <div className='row'>
              <div className='col-md-6 steps-section-img'>
                <img src={home_step_three} />
              </div>
              <div className='col-md-6 steps-section-text'>
                <h1>3. Aidrop to your<br />community</h1>
                <p>Collect wallet addresses from your community and Airdrop your NFTs to new holders instantly. You can still change metadata, even after the tokens are minted.</p>
                {/* <a><button>Create NFT Smart Contract</button></a> */}
              </div>
            </div>
          </div>
        </div>

        <div className='container-fluid'>
          <div className='row advantages-section animate__animated animate__fadeIn'>
            <div className='col-md-4'>
              <div className='advantages-single as-1'>
                <img alt="Instant contract creation" src={icon1} />
                <h2>Instant contract creation</h2>
                <p>Create your contract in 3 steps, first on etherscan. No need to add metadata or files. Add or change URI to IPFS later.</p>
              </div>
            </div>
            <div className='col-md-4'>
              <div className='advantages-single as-2'>
                <img alt="Instant contract creation" src={icon2} />
                <h2>Dynamic customizable NFTs</h2>
                <p>Control over URI links allows updates to visuals and metadata of your NFTs. Anytime.</p>
              </div>
            </div>
            <div className='col-md-4'>
              <div className='advantages-single as-3'>
                <img alt="Instant contract creation" src={icon3} />
                <h2>No-code contract generator</h2>
                <p>Our guided wizard is so simple, even your grandma could deploy it.</p>
              </div>
            </div>
          </div>
          <div className='row advantages-section'>
            <div className='col-md-4'>
              <div className='advantages-single as-4'>
                <img alt="Instant contract creation" src={icon4} />
                <h2>Anonymous contract deploy</h2>
                <p>With only wallet needed, Droplove is non-custiodial tool for minting your contracts and tokens.</p>
              </div>
            </div>
            <div className='col-md-4'>
              <div className='advantages-single as-5'>
                <img alt="Instant contract creation" src={icon5} />
                <h2>Transfer Contract Ownership</h2>
                <p>Deploy contract and transfer it to the new owner.</p>
              </div>
            </div>
            <div className='col-md-4'>
              <div className='advantages-single as-6'>
                <img alt="Instant contract creation" src={icon6} />
                <h2>Testing on real test networks</h2>
                <p>Try your contracts in a real environment, such as Rinkeby or Kovan directly, and see the fresh mints on Opensea Testnets.</p>
              </div>
            </div>
          </div>
        </div>

        <div className='container'>
          <div className='section-newsletter animate__animated animate__fadeIn'>
            <img src={newsletter_circle} />
            <h2>Droplove Insiders Club </h2>
            <p>Join Droplove Insiders Community to get Whitelists and tips.</p>
            <a target="_blank" href="https://478d174b.sibforms.com/serve/MUIEAKAGot38Ap8rdsPMnjT84bgBvms1ZXzmmllNu3YSWF9dNmtQFNhuCbMAIKxXcBAVL3h2ItN3kFVWE8OO8TyJZolFRW8U9T6Ox3ICoi4Ah6WSxz6fgsy3vRBZ6nBr_oH75AhbaN_p2bKpA0dacLD-yq543XB-z-RljtQ6gs-dcjANMkrnbv_fiIRf86J9mAesvaXzUx08Y_Lo"><button className="btn-border">Subscribe</button></a>
          </div>
        </div>

        <div className="container-fluid no-padding">
          <div className="invented-section animate__animated animate__fadeIn">
            <div className='container'>
              <h2>Invented for makers</h2>
              <div className="product-hunt-badge">
                <a href="https://www.producthunt.com/products/droplove?utm_source=badge-top-post-badge&utm_medium=badge&utm_souce=badge-droplove" target="_blank"><img src="https://api.producthunt.com/widgets/embed-image/v1/top-post-badge.svg?post_id=352687&theme=dark&period=daily" alt={"Droplove - No&#0045;code&#0032;tool&#0032;to&#0032;deploy&#0032;your&#0032;own&#0032;NFT&#0032;smart&#0032;contracts&#0046; | Product Hunt"} styles="width: 250px; height: 54px;" width="250" height="54" /></a>
              </div>
              <p>We are reverse archeologists of Web3. One day, not far from today, everything will be tokenized in a smart contract. Some digital assets created now, will be enjoying godlike status of almost mythical objects. Everyone should have a shot at attempting to create such collections in web3, as early and as creatively as possible. The technology should be rather a gate, than a barrier.</p>
              <p>Droplove has been created with all this in mind. Its simple, no-code UI, allows the owners to deploy their own smart contract and mint NFT passes without a line of code.</p>
              <p>Web3 is magical, because it allows us to give something special to our communities. With Droplove, your first gift could be a powerful NFT pass, unlocking the potential of a decentralized community. </p>
              <div className="mintpass-section animate__animated animate__fadeIn">
                <img src={droplove_light_logo} className="" alt="Droplove logo" />
                <h2>Unlock Droplove Pro<br/>with DRPLV pass</h2>
                <p>Deploy unlimited NFT pass contracts on<br/>Ethereum Mainnet with DRPLV  token.</p>
                <a href="/droplove-pro"><button className='full-btn-blue-medium'>Learn more</button></a>
                <br />
                <br />
                <br />
                <a className="text-link-small" href="https://discord.gg/juB69MkDF2">Join our Discord</a>
              </div>
            </div>
          </div>
        </div>
      </Container>
    </div>
  );
}

export default Home;
