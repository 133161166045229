// import logo from './logo.svg';
import './App.css';
import 'animate.css'
import React from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";

import Header from "./components/Header/Header"
import Footer from "./components/Footer/Footer"
import Home from "./pages/Home/Home"
import Contact from './pages/Contact/Contact'
import Disclaimer from './pages/Disclaimer/Disclaimer'
import DroplovePro from './pages/DroplovePro/DroplovePro'

function App() {
  return (
    <div className="App">
      <Router>
        <Header />
        <Switch>
          <Route path="/" exact component={Home} />
          <Route path="/contact" exact component={Contact} />
          <Route path="/disclaimer" exact component={Disclaimer} />
          <Route path="/droplove-pro" exact component={DroplovePro} />
          <Route>404 Not Found!</Route>
        </Switch>
        <Footer />
      </Router>
    </div>
  );
}

export default App;
