import './Contact.css';
//libs imports
import React, { useEffect, useState } from "react";
import { Container } from '@material-ui/core'

const Contact = () => {

    return (
        <div>
            <Container className="no-padding" maxWidth="xl">
                <div className='contact-page-body'>
                    <h1>Droplove Contacts</h1>
                    <div className='row contact-page-row'>
                        <div className='col-md-4'>
                            <div className='contact-item'>
                                <h3>Petra Kemkova</h3>
                                <a href='mailto:petra@droplove.xyz' type='email'><p>petra@droplove.xyz</p></a>
                                <a target="_blank" href='https://www.linkedin.com/in/petrakemkova/'><button className="btn-white-wizard">Linkedin</button></a>
                            </div>
                        </div>
                        <div className='col-md-4'>
                            <div className='contact-item'>
                                <h3>Andrej Kiszling</h3>
                                <a href='mailto:andrej@droplove.xyz' type='email'><p>andrej@droplove.xyz</p></a>
                                <a target="_blank" href='https://www.linkedin.com/in/kiszling/'><button className="btn-white-wizard">Linkedin</button></a>
                            </div>
                        </div>
                        <div className='col-md-4'>
                            <div className='contact-item'>
                                <h3>Ondrej Sarnecky</h3>
                                <a href='mailto:ondrej@droplove.xyz' type='email'><p>ondrej@droplove.xyz</p></a>
                                <a target="_blank" href='https://www.linkedin.com/in/ondrej-sarnecky/'><button className="btn-white-wizard">Linkedin</button></a>
                            </div>
                        </div>
                    </div>
                </div>
            </Container>
        </div>
    );
}

export default Contact;