import './DroplovePro.css';
//assests import
import upcomingdrop from '../../assets/upcomingdrop.png'
import upcomingdrop_geishas from '../../assets/upcomingdrop_geishas.png'
import upcomingdrop_almo from '../../assets/upcomingdrop_almo.png'
import upcomingdrop_avatar_rene from '../../assets/upcomingdrop_avatar_rene.png'
import upcomingdrop_avatar_geishas from '../../assets/upcomingdrop_avatar_geishas.png'
import upcomingdrop_avatar_almo from '../../assets/upcomingdrop_avatar_almo.png'
import iconMinus from '../../assets/Icon-button-minus.png'
import iconPlus from '../../assets/Icon-button-plus.png'
import mintpass_gif from '../../assets/mintpass-gif.gif'
import shaman1 from '../../assets/shamans/shaman1.png'

//libs imports
import { Container } from '@material-ui/core'
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import DroplovePassNFT from '../../contracts/DroplovePassNFT.json'
import { useWeb3React } from "@web3-react/core"
import { injected } from "../../components/Wallet/connector"

const DroplovePro = () => {
    const dispatch = useDispatch();
    const [mintAmount, setMintAmount] = useState(1);
    const [tokenTotalSupply, setTotalSupply] = useState(0);
    const [contractState, setContractState] = useState({});

    const { active, account, library, connector, activate, deactivate } = useWeb3React()

    useEffect(() => {
        if (!active) {
            connectWallet()
        } else {
            loadMintpassProps()
            checkNetwork()
        }
    }, [active]);

    const checkNetwork = async () => {
        // const networkId = await library.eth.net.getId();

        // TODO: Apply network checker
    }

    const connectWallet = async () => {
        try {
            await activate(injected)
        } catch (ex) {
            console.log(ex)
        }
    }

    const loadMintpassProps = async () => {
        // const networkId = await library.eth.net.getId();
        try {
            const droploveContract = new library.eth.Contract(
                DroplovePassNFT.abi,
                DroplovePassNFT.networks["1"].address
            );

            setContractState(droploveContract)

            const totalSupply = await droploveContract.methods
                .totalSupply()
                .call();

            setTotalSupply(totalSupply)
        } catch (error) {
            console.error("Error", error);
            // alert(
            //   "Contracts not deployed to the current network " +
            //   networkId.toString()
            // );
        }
    }

    const counterPlus = async () => {
        if (mintAmount >= 1 && mintAmount <= 5 && mintAmount != 5)
            setMintAmount(mintAmount + 1)
    }

    const counterMinus = async () => {
        if (mintAmount != 1 && mintAmount <= 5)
            setMintAmount(mintAmount - 1)
    }

    const mintPass = async (qty) => {
        try {
            const receipt = await contractState.methods
                .mint(qty)
                .send({ from: account, value: 35000000000000000 * qty });

            console.log(receipt);
            // console.log(receipt.events.Transfer.returnValues.tokenId);

            const newSupply = await contractState.methods
                .totalSupply()
                .call();

            setTotalSupply(newSupply)
        } catch (error) {
            // alert(error.message);
            console.error(error);
        }
    }

    return (
        <div>
            <Container className="no-padding" maxWidth="xl">
                <div className=''>
                    <div className="droplove-pro-body">
                        <div className='container'>
                            <div className='droplove-pro-mintpass'>
                                <h1>Droplove Pro</h1>
                                <h4>Unlock access to our no-code creator for Ethereum mainnet with DRPLV Mint Pass. Start creating your NFTs with entire smart contracts now.</h4>
                                <div id="mintpass" className='container'>
                                    <div className="mintpass-pro-section animate__animated animate__fadeIn">
                                        <div className="mintpass-section-top">
                                            <div className='mintpass-section-top-miniheading'>
                                                <p>Get beta access to No-Code Contract Generator</p>
                                            </div>
                                            <img src={mintpass_gif} className="" alt="Droplove logo" />
                                            <h2>Get DRPLV Mint Pass</h2>
                                            <p>We have built Droplove to democratize the world of NFT drops. Get Mint Pass and join our community of early adopters, who will be able to use our no-code NFT contract creator first.</p>
                                            <br />
                                            <p>Each DRPLV Pass has two utilities:</p>
                                            <br />
                                            <p>Access to no-code contract creator Droplove PRO,<br />enabling you to drop your own NFTs.<br />+<br />Presale access with discount to all upcoming drops.</p>
                                        </div>
                                        <div className="mintpass-section-bottom">
                                            <div className="mint-counter">
                                                <img onClick={() => counterMinus()} src={iconMinus} className="" alt="logo" />
                                                <span className="mintpass-counter">{mintAmount}</span>
                                                <img onClick={() => counterPlus()} src={iconPlus} className="" alt="logo" />
                                            </div>
                                            <div className="pass-minter">
                                                {/* <a href="https://discord.gg/juB69MkDF2"><button className='full-btn-big'>Learn more</button></a> */}
                                                <button className="full-btn-blue-medium" onClick={() => mintPass(mintAmount)}>{active ? "Mint" : "Connect your wallet"}</button>
                                                {/* <div className='pay-with-card-btn-section'>
                                                    <button className="full-btn-blue-medium" onClick={() => mintPass(mintAmount)}>{`Pay with card`}</button>
                                                </div> */}
                                                <p className="price-info">From 0.035 Eth</p>
                                                <a className="text-link-small" href="https://discord.gg/juB69MkDF2">Join our Discord</a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className='container-fluid'>
                            <div className='droplove-pro-shamans-section'>
                                <h2>DRPLV Shamans</h2>
                                <h4>DRPLV Mint Passes come with art of Droplove Shamans, with more than 100 traits, making each pass unique. Besides rarity, each holder of Shaman gets automatic whitelists or discounts to all future Droplove NFT Collections.</h4>
                                <div className='droplove-pro-shamans'>
                                    <div className='row'>
                                        <div className='shamans-item'>
                                            <div className='shamans-item-inner'>
                                                <div className='shamans-item-top'>
                                                    <img src={'https://i.seadn.io/gae/vVbLy9logwIac-72CZOTJEIE9jdRoMX3SoDK0eNUXM6i9QRnH5hFAEY4SRZ331SWyrzkqEaGi_QSDQc2kvsmHGwPySLYR0nq_pir5w?auto=format&w=384'} />
                                                </div>
                                                <div className='shamans-item-bottom'>
                                                    <p>Droplove Pass #54</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='shamans-item'>
                                            <div className='shamans-item-inner'>
                                                <div className='shamans-item-top'>
                                                    <img src={'https://i.seadn.io/gae/bvTFQ-JDgx-7G2Tm1_AQdigiEuP73RJF3EjJuPH_gZ4xd-74PcxDOZF7MFIa4wT0vUYkmc15od7-0F-6EOhuv7lydgUlkKh0ThkA0Q?auto=format&w=384'} />
                                                </div>
                                                <div className='shamans-item-bottom'>
                                                    <p>Droplove Pass #57</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='shamans-item'>
                                            <div className='shamans-item-inner'>
                                                <div className='shamans-item-top'>
                                                    <img src={'https://i.seadn.io/gae/QZxZwmck1DqMGI3UXqnMqLdTIPZyNP5Fco1gwHn6wMT7xEu9adLdJMyfE_9XIAr0lfAlq1T1IQhQPNdLw_L5Zs_feALurX--ydBtzQ?auto=format&w=384'} />
                                                </div>
                                                <div className='shamans-item-bottom'>
                                                    <p>Droplove Pass #4</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='shamans-item'>
                                            <div className='shamans-item-inner'>
                                                <div className='shamans-item-top'>
                                                    <img src={'https://i.seadn.io/gae/g9kK3QeNxkkQahyPyG_ONmm7TlrYHMLSwgNWa4mYxP5Dze8H8MgVdXYyhmK2u_Ze_m8UcjwLmtERWWWojcJfLwYg4T2ldUTZHs7YKw?auto=format&w=384'} />
                                                </div>
                                                <div className='shamans-item-bottom'>
                                                    <p>Droplove Pass #39</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='shamans-item'>
                                            <div className='shamans-item-inner'>
                                                <div className='shamans-item-top'>
                                                    <img src={'https://i.seadn.io/gae/mwJvlIK2752cIxeZgnxBJyW3iOJuslPpUl3yA_M_MNqtBEZkorckpYlml9q7NjwL-j-Ar9UEj2bmBiItCC0DDoJqDTmfA81UHn3lCg?auto=format&w=384'} />
                                                </div>
                                                <div className='shamans-item-bottom'>
                                                    <p>Droplove Pass #25</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='shamans-item'>
                                            <div className='shamans-item-inner'>
                                                <div className='shamans-item-top'>
                                                    <img src={'https://i.seadn.io/gae/Y5-TM8ZyqidJ8kVLoSnSB5qUSgHfHjDk3s0P7H35i6QDMq9r_epuRGyv5322MPNpU1vBALg9PjjCHoBt6GAElc2GBI-msCZVJulFQL8?auto=format&w=384'} />
                                                </div>
                                                <div className='shamans-item-bottom'>
                                                    <p>Droplove Pass #18</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='shamans-item'>
                                            <div className='shamans-item-inner'>
                                                <div className='shamans-item-top'>
                                                    <img src={'https://i.seadn.io/gae/YCezDuv1xabd7ilhl10w-1Z_x0Mm2wRwmyz7OpHmWed26NV-UGMD9GDkyGL-xTBDMtKh5fH2DbmfMTkXMRgjQRP7UDacHRxqQbEIk1s?auto=format&w=384'} />
                                                </div>
                                                <div className='shamans-item-bottom'>
                                                    <p>Droplove Pass #54</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='shamans-item'>
                                            <div className='shamans-item-inner'>
                                                <div className='shamans-item-top'>
                                                    <img src={'https://i.seadn.io/gae/CEpZUY0_FUT1A_3PUuW_Mz3HuRySBaeGNHx8r9alSjYWzvE1waxwHyppZWf0uqhYWhMbA52-WYA97A9zTi3N6oZMCIvzUByPVIaI?auto=format&w=384'} />
                                                </div>
                                                <div className='shamans-item-bottom'>
                                                    <p>Droplove Pass #1</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='shamans-item'>
                                            <div className='shamans-item-inner'>
                                                <div className='shamans-item-top'>
                                                    <img src={'https://i.seadn.io/gae/xHo0WADEkXQLo3Z0MZ3NGvvQbb4tH3sLIzxISQxX7e3X5x0jr5sVOzc0t-jpr0bLj_pERRhpzMgbDunWg5iWXAyRC0uOfdorGS1l-A?auto=format&w=384'} />
                                                </div>
                                                <div className='shamans-item-bottom'>
                                                    <p>Droplove Pass #51</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='shamans-item'>
                                            <div className='shamans-item-inner'>
                                                <div className='shamans-item-top'>
                                                    <img src={'https://i.seadn.io/gae/uz-u7wq4ot00PEWBDFoRoDxYcmfxg5axnqmJl_8QRl4koBJbn3ZLbx9RGgF9escmIYYfEcdSLVPUoeC_N-497PNtjHEjMk3NXEpbrg?auto=format&w=384'} />
                                                </div>
                                                <div className='shamans-item-bottom'>
                                                    <p>Droplove Pass #52</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='shamans-item'>
                                            <div className='shamans-item-inner'>
                                                <div className='shamans-item-top'>
                                                    <img src={'https://i.seadn.io/gae/gqWbHjC2gLw203cPJk_SYZ_Z5CB0rUL_k1DnV7-MOcxp3q5j121OC7Os7mzR02tym-UbiSn3J8u317NYVBL68tXlf7LPWe05Nz6K?auto=format&w=384'} />
                                                </div>
                                                <div className='shamans-item-bottom'>
                                                    <p>Droplove Pass #53</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='shamans-item'>
                                            <div className='shamans-item-inner'>
                                                <div className='shamans-item-top'>
                                                    <img src={'https://i.seadn.io/gae/HUu-foV2b5ZDsMytThlA6Ssow2aOVbU9W-9AMG0_LLoHyQ5H90qoVcY7A3L5jqvn5Esny15a4YKu1zGjq565KQDHQexmOhEbb8ik2g?auto=format&w=384'} />
                                                </div>
                                                <div className='shamans-item-bottom'>
                                                    <p>Droplove Pass #35</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='shamans-item'>
                                            <div className='shamans-item-inner'>
                                                <div className='shamans-item-top'>
                                                    <img src={'https://i.seadn.io/gae/GrP7Tvv9any4pgA3WRcHkyYPZTI2LeBv9CjxSItSLbbOCCkVyitTtBwd2HbeZYwbITyId9gqXvDiXF8CoFRNb0f-b9Y2vbMi5vQ_zA?auto=format&w=384'} />
                                                </div>
                                                <div className='shamans-item-bottom'>
                                                    <p>Droplove Pass #36</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='shamans-item'>
                                            <div className='shamans-item-inner'>
                                                <div className='shamans-item-top'>
                                                    <img src={'https://i.seadn.io/gae/ncNw99MPfiYpMQF5ZdY-W_51TdHdrUQfPG8oixWHhOOshpp7TciZcisJSDczzP1IQW27t7nEJI1X_78uA5n5057WPqg2HOayh4i1gA?auto=format&w=384'} />
                                                </div>
                                                <div className='shamans-item-bottom'>
                                                    <p>Droplove Pass #37</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='shamans-item'>
                                            <div className='shamans-item-inner'>
                                                <div className='shamans-item-top'>
                                                    <img src={'https://i.seadn.io/gae/LaKfUHRB3VWNWT9g3WhP-zerVEY0e0qDL9woHbBvedmbdM1yjnalrAE6y_4O5gvfXYsn-JKLkUeWsYkiYPTxn7GgiD4FomRX-Q9seQ?auto=format&w=384'} />
                                                </div>
                                                <div className='shamans-item-bottom'>
                                                    <p>Droplove Pass #38</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className='container-fluid'>
                            <div className='droplove-pro-why-drplv animate__animated animate__fadeIn'>
                                <div className='container'>
                                    <h2>Why to hold DRPLV mint pass? </h2>
                                    <p>DRPLV is powerful non-fungible token unlocking access to whole Droplove No-Code Creator, allowing to create smart contracts and airdrop NFTs entirely without code.<br /><br />However, besides this, each DRPLV NFT serves as tradtional presale access pass to future projects made by Droplove Friends. Holders of DRPLV will be eligible to whitelist, presales or discounts, along with the special roles in future projects.</p>
                                </div>
                                <div className="container upcoming-drop-section">
                                    <div className="row upcoming-drop-title">
                                        <h1>Upcoming NFT collectible projects</h1>
                                        <div className='homepage-right-view-all'><a>view all drops (soon)</a></div>
                                    </div>
                                    <div className='row'>
                                        <div className='col-md-4 col-xs-12'>
                                            <div className="upcoming-drop-window">
                                                <div className="upcoming-img">
                                                    <img src={upcomingdrop_geishas} />
                                                </div>
                                                <div className="upcoming-info">
                                                    <div className='upcoming-info-avatar'>
                                                        <img src={upcomingdrop_avatar_geishas} />
                                                    </div>
                                                    <span>@inhideandseek</span>
                                                    <h3>Unimpressed Geisha</h3>
                                                    <p>TBA</p>
                                                    <a target="_blank" href='https://www.unimpressedgeisha.com/'><button className="full-btn-upcomming">Official</button></a>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='col-md-4 col-xs-12'>
                                            <div className="upcoming-drop-window">
                                                <div className="upcoming-img">
                                                    <img src={upcomingdrop} />
                                                </div>
                                                <div className="upcoming-info">
                                                    <div className='upcoming-info-avatar'>
                                                        <img src={upcomingdrop_avatar_rene} />
                                                    </div>
                                                    <span>@renhe</span>
                                                    <h3>Crystalian</h3>
                                                    <p>0.09 ETH | 0.05 ETH for DRPLV holders</p>
                                                    <a target="_blank" href='https://discord.com/invite/VNv3xDZzkH'><button className="full-btn-upcomming">Join Discord</button></a>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='col-md-4 col-xs-12'>
                                            <div className="upcoming-drop-window">
                                                <div className="upcoming-img">
                                                    <img src={upcomingdrop_almo} />
                                                </div>
                                                <div className="upcoming-info">
                                                    <div className='upcoming-info-avatar'>
                                                        <img src={upcomingdrop_avatar_almo} />
                                                    </div>
                                                    <span>@bessaillustration</span>
                                                    <h3>Almo</h3>
                                                    <p>TBA</p>
                                                    <button disabled className="full-btn-upcomming">Comming soon</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='container'>
                            <div className='droplove-pro-faq-section'>
                                <h2>FAQ</h2>
                                <div className='faq-item'>
                                    <h4>What is needed to create and deploy smart contract on ETH mainnet?</h4>
                                    <p>Only DRPLV pass and Metamask wallet.</p>
                                </div>
                                <div className='faq-item'>
                                    <h4>Do I need to prepare metadata or any code before start?</h4>
                                    <p>No. Our 3 step wizard will guide you click by click through whole process. Make sure to decide on Supply (how many mints do you plan), name of project and ticker (acronym of project).</p>
                                </div>
                                <div className='faq-item'>
                                    <h4>Should I prepare visual files first?</h4>
                                    <p>No need. You can always change or link visuals for your NFTs later in Dashboard.</p>
                                </div>
                                <div className='faq-item'>
                                    <h4>How would I manage the smart contract?</h4>
                                    <p>Once your contracts are created, you can manage them in Dashboard. Add or update content links, mint NFTs or transfer whole smart contract to new owner. </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Container>
        </div>
    );
}

export default DroplovePro;
