import './Disclaimer.css';
//libs imports
import React, { useEffect, useState } from "react";
import { Container } from '@material-ui/core'

const Disclaimer = () => {

    return (
        <div>
            <Container className="no-padding" maxWidth="xl">
                <div className='disclaimer-page-body'>
                    <h1>Disclaimer</h1>
                    <p>
                        Droplove is software using a set of smart contracts on Ethereum Blockchain. Your use of the Droplove involves various risks, including, but not limited to, losses caused with high network fees. Before using the Droplove, you should review the relevant documentation to make sure you understand how the Droplove app, Ethereum Blockchain and non-fungible tokens work.
                    </p>
                    <p>
                        You expressly agree that access to and use of the site is at your sole risk and is provided on an “as is” and “as available” basis without warranties of title or implied warranties of non-infringement , merchantability or fitness for a particular purpose (except only to the extent prohibited under the laws applicable to terms of service with any legally required warranty period to the shorter of thirty days from use or the minimum period required). Without limiting the foregoing, neither Droplove nor its affiliates or subsidiaries, or any of their directors, officers, employees, contractors, agents, attorneys, third-party providers, distributors, licensees, licensors, successors or assigns (collectively, “company parties”) warrant that the site will be uninterrupted, bug-free or error-free, and none of the company parties warrant that smart contracts are merchantable, fit for any particular purpose, and/or recognized by any particular jurisdiction(s).
                    </p>
                    <p>
                        To the fullest extent permitted by law, the disclaimers of liability contained herein apply to any and all damages, losses and/or injury whatsoever caused by or related to use of, or inability to use, the site. You further specifically acknowledge that the company parties are not liable, and you agree not to seek to hold the company parties liable, for the conduct of third parties, including other users of the site and operators of external websites, and that the risk of the site and external websites and of injury from the foregoing rest entirely with you.
                    </p>
                    <p>
                        In that event that a court and/or arbitrator(s) of competent jurisdiction holds that any company party is liable to you or otherwise.
                    </p>
                    <p>
                        We do not guarantee that the Site will be secure or free from bugs or viruses.
                    </p>
                    <p>
                        You are responsible for configuring your information technology, computer programs and platform in order to access the Site. You should use your own virus protection software.
                    </p>
                    <p>
                        We cannot promise that the use of the Site, or any content taken from the Site, will not infringe the rights of any third party.
                    </p>
                    <p>
                        Certain content and materials available on the site are for informational purposes only and are not intended to address your particular requirements. <strong>In particular the content and materials available on the Site do not constitute any form of advice or recommendation by us, should not be regarded as an offer, solicitation, invitation or recommendation to buy or sell investments, securities or any other financial services and are not intended to be relied upon by you in making any specific investment or other decisions. We recommend that you seek independent advice from your own financial advisors and legal counsel before making any such decision.</strong>
                    </p>
                    <p>
                        Nothing included in the Site constitutes an offer or solicitation to sell, or distribution of, investments and related services by Droplove to anyone in any jurisdiction.
                    </p>
                    <p>
                        You may only participate with Smart Contracts on the Site by linking your Cryptocurrency Wallet on supported bridge extensions such as MetaMask. Metamask (or other cryptocurrency wallet), is an electronic wallet that allows you to purchase, store and engage in transactions using Ethereum & other cryptocurrencies. Before putting your Cryptocurrency Asset into a Smart Contract, you will be required to download a supported electronic wallet extension and connect and unlock your Cryptocurrency Wallet with that extension.
                    </p>
                    <p>
                        All transactions initiated through our service are facilitated and run by third party electoriny wallet extensions, and by using our services you agree that you are governed by the terms of service and privacy policy for the applicable extensions.
                    </p>
                    <p>
                        <strong>Payments Related to the Sale and Purchase of NFTs:</strong>
                    </p>
                    <p>
                        <strong>Purchase of NFTs: </strong>Seller-determined prices for NFTs may be displayed on or through the Platform via Seller’s Website when Lazy Minting is utilized.
                    </p>
                    <p>
                        The contract for the purchase of NFTs is between Buyer and Seller. <strong>BUYER BEARS FULL RESPONSIBILITY FOR VERIFYING THE IDENTITY, LEGITIMACY, INTEGRITY, IP OWNERSHIP, AND AUTHENTICITY OF NFTS BUYER PURCHASES ON OR THROUGH THE PLATFORM.</strong>
                    </p>
                    <p>
                        <strong>Ownership of NFT: </strong>Because each NFT purchased on or through the Platform or using its Services is an NFT associated with a hash on Blockchain Technology, when you purchase an NFT in accordance with these Terms of Use, any license you may obtain is from the Seller and not the Platform.
                    </p>
                    <p>
                        <strong>Sale of NFTs: </strong>Sellers are responsible for all costs related to or arising out of Transactions such as minting costs, if applicable, returns of NFTs sold, taxes, fees, penalties, and fraud. Sellers shall reimburse us for any and all such costs (including penalties).
                    </p>
                </div>
            </Container>
        </div>
    );
}

export default Disclaimer;
