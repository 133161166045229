import React from "react";
import './Header.css';
import droplove_new_logo_left from '../../assets/droplove_new_logo_left.png'
import discord from '../../assets/discord.png'
import twitter from '../../assets/twitter.png'

import { Container } from '@material-ui/core'

const Header = (props) => {

  return (
    <div className="fixed-header">
      <Container className="no-padding" maxWidth="xl">
        <div className="container-fluid">
          <div className="droplove-header">
            <div className="droplove-logo">
              <a href="/">
                <img className="logo-left" src={droplove_new_logo_left} alt="logo" />
                <h1>Droplove</h1>
              </a>
            </div>
            <div className="droplove-menu">
              <ul>
                <li>
                  <a target="_blank" className="apply-btn" href="https://droplove.gitbook.io/droplove-docs/">Docs</a>
                  <a target="_blank" className="apply-btn" href="/droplove-pro">Unlock Droplove PRO</a>
                  <a target="_blank" className="apply-btn" href="https://medium.com/droplove">Blog</a>
                  <a href="https://discord.gg/juB69MkDF2"><img width="24" src={discord} className="" alt="logo" /></a>
                  <a href="https://twitter.com/droplovexyz"><img width="24" src={twitter} className="" alt="logo" /></a>
                  {/* <a className="apply-btn" href="#">Explore</a> */}
                  <a target="_blank" className="mobile-hide" href="https://app.droplove.xyz/"><button className="full-btn">Launch app</button></a>
                </li>
              </ul>
            </div>
          </div>
          <a className="desktop-hide" href="https://app.droplove.xyz/"><button className="full-btn">Launch app</button></a>
        </div>
      </Container>
    </div>
  );
}

export default Header;
